import Vue from 'vue'

function ShareyuePlugin(Vue, pluginOptions) {

    let pluginOpts = Object.assign({}, {
        api: ""
    }, pluginOptions);

    const registers = require.context('./register', true, /[A-Za-z0-9-_,\s]+\.js$/i);

    var result = {};

    registers.keys().sort().forEach(key => {
        Object.assign(result, registers(key).default(Vue, pluginOpts, result));
    });

    Object.defineProperties(Vue.prototype, {
        $shareyue: {
            get() {
                result.$vue = this;
                return result;
            }
        }
    });
    if (process.env.NODE_ENV === "development")
        console.error(result);
}
let pluginOptions = {
    api: "/api/",
    login: "/login",
}
if (process.env.NODE_ENV === "development") {
    Object.assign(pluginOptions, {});
} else {
    Object.assign(pluginOptions, {});
}


Vue.use(ShareyuePlugin, pluginOptions);