import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: false,
    systemTitle: document.title,
    menus: [{
        key: "home",
        title: "主页",
        icon: "mdi-home",
        url: "/index"
      },
      {
        key: "biochip",
        title: "芯片管理",
        icon: "mdi-speaker-wireless",
        url: "/biochip"
      },
      {
        key: "peasanthousehold",
        title: "农户管理",
        icon: "mdi-calendar-account",
        url: "/peasanthousehold"
      },
      {
        key: "staff",
        title: "员工管理",
        icon: "mdi-account-supervisor",
        url: "/staff"
      },
      {
        key: "admin",
        title: "管理员管理",
        icon: "mdi-account-box",
        url: "/admin"
      },
    ]
  },
  mutations: {
    showLoading(state) {
      state.loading = true;
    },
    hideLoading(state) {
      state.loading = false;
    }
  },
  actions: {},
  modules: {}
})