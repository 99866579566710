<template>
  <v-app>
    <router-view></router-view>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  computed: {
    ...mapState(["loading"]),
  },
  data: () => ({}),
};
</script>
