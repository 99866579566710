export default function (Vue, pluginOptions, owner) {
    let result = {};

    Object.assign(result, {
        login(options) {
            let opts = Object.assign({}, {
                account: "",
                password: ""
            }, options);
            return new Promise((resolve, reject) => {
                this.$vue.$shareyue.requestPost({
                    api: "admin/login",
                    data: opts
                }).then((res) => {
                    switch (res.status) {
                        case 404:
                            {
                                reject("账号或密码错误");
                                break;
                            }
                        default:
                            {
                                this.$vue.$shareyue.setToken({
                                    accessToken: res.data.accessToken,
                                    refreshToken: res.data.refreshToken
                                });
                                resolve(res.data);
                                break;
                            }
                    }


                }).catch(reject);
            });
        },
        logoff(options) {
            let opts = Object.assign({}, {

            }, options);
            return new Promise((resolve, reject) => {
                this.$vue.$shareyue.deleteToken();
                resolve();
            });
        },
        refreshToken(options) {
            let opts = Object.assign({}, {
                accessToken: "",
                refreshToken: ""
            }, this.$vue.$shareyue.getToken(), options);
            return new Promise((resolve, reject) => {
                this.$vue.$shareyue.requestPut({
                    api: "jwttoken",
                    data: opts
                }).then(res => {
                    if (res.status == 404) {
                        reject(res);
                    } else {
                        this.$vue.$shareyue.setToken({
                            accessToken: res.data.accessToken,
                            refreshToken: res.data.refreshToken
                        });
                        resolve(res.data);
                    }
                }).catch(reject);
            });
        },
        getUser(options) {
            var opts = Object.assign({}, {

            }, options);

            return new Promise((resolve, reject) => {
                this.$vue.$shareyue.requestGet({
                    api: "admin",
                    data: opts
                }).then((res) => {
                    if (res.status == 200) {
                        resolve(res.data);
                    } else {
                        reject(res.errors);
                    }
                }).catch(reject);
            });
        }
    });

    return result;
}