import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
    path: '',
    redirect: "/index",
    component: () => import('../views/shared/Master.vue'),
    children: [{
        path: 'index',
        component: () => import('../views/Index.vue'),
        meta: {
          menu: "home"
        }
      },
      {
        path: 'biochip',
        component: () => import('../views/Biochip.vue'),
        meta: {
          menu: "biochip"
        }
      },
      {
        path: 'staff',
        component: () => import('../views/Staff.vue'),
        meta: {
          menu: "staff"
        }
      },
      {
        path: 'staff/add',
        component: () => import('../views/StaffAdd.vue'),
        meta: {
          menu: "staff"
        }
      },
      {
        path: 'admin',
        component: () => import('../views/Admin.vue'),
        meta: {
          menu: "admin"
        }
      },
      {
        path: 'admin/add',
        component: () => import('../views/AdminAdd.vue'),
        meta: {
          menu: "admin"
        }
      },
      {
        path: 'peasanthousehold',
        component: () => import('../views/PeasantHousehold.vue'),
        meta: {
          menu: "peasanthousehold"
        }
      },
      {
        path: 'peasanthousehold/add',
        component: () => import('../views/PeasantHouseholdAdd.vue'),
        meta: {
          menu: "peasanthousehold"
        }
      },
    ]
  },
  {
    path: '/login',
    component: () => import('../views/Login.vue'),
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router