export default function (Vue, pluginOptions, owner) {
    let result = {};

    Object.assign(result, {
        getStaffs(options) {
            let opts = Object.assign({}, {

            }, options);
            opts.page = opts.page - 1;
            return new Promise((resolve, reject) => {
                this.$vue.$shareyue.requestGet({
                    api: "admin/staff",
                    data: opts
                }).then(res => {
                    resolve(res.data);
                }).catch(reject);
            });
        },
        getStaff(id) {
            return new Promise((resolve, reject) => {
                this.$vue.$shareyue.requestGet({
                    api: `admin/staff/${id}`
                }).then(res => {
                    resolve(res.data);
                }).catch(reject);
            });
        },
        addStaff(options) {
            this.$vue.$store.commit('showLoading');
            let opts = Object.assign({}, {
                account: "",
                password: "",
                name: "",
            }, options);
            return new Promise((resolve, reject) => {
                this.$vue.$shareyue.requestPost({
                    api: `admin/staff`,
                    data: opts
                }).then(res => {
                    switch (res.status) {
                        case 201: {
                            resolve(res.data);
                            break;
                        }
                        case 409: {
                            reject(res.data);
                            break;
                        }
                        default: {
                            reject();
                        }
                    }
                }).catch(reject).finally(() => {
                    this.$vue.$store.commit('hideLoading');
                });
            });
        },
        editStaff(id, options) {
            this.$vue.$store.commit('showLoading');
            let opts = Object.assign({}, {
                account: "",
                password: "",
                name: "",
            }, options);
            return new Promise((resolve, reject) => {
                this.$vue.$shareyue.requestPut({
                    api: `admin/staff/${id}`,
                    data: opts
                }).then(res => {
                    switch (res.status) {
                        case 202: {
                            resolve(res.data);
                            break;
                        }
                        case 409: {
                            reject(res.data);
                            break;
                        }
                        default: {
                            reject();
                        }
                    }
                }).catch(reject).finally(() => {
                    this.$vue.$store.commit('hideLoading');
                });
            });
        },
        deleteStaff(id) {
            this.$vue.$store.commit('showLoading');
            return new Promise((resolve, reject) => {
                this.$vue.$shareyue.requestDelete({
                    api: `admin/staff/${id}`
                }).then(res => {
                    switch (res.status) {
                        case 204: {
                            resolve(res);
                            break;
                        }
                        default: {
                            reject();
                        }
                    }
                }).catch(reject).finally(() => {
                    this.$vue.$store.commit('hideLoading');
                });
            });
        }
    });

    return result;
}