import Vue from 'vue'

function ShareyueApiPlugin(Vue, pluginOptions) {

    let pluginOpts = Object.assign({}, {
    }, pluginOptions);

    const registers = require.context('./register', true, /[A-Za-z0-9-_,\s]+\.js$/i);

    var result = {};

    registers.keys().sort().forEach(key => {
        Object.assign(result, registers(key).default(Vue, pluginOpts, result));
    });

    Object.defineProperties(Vue.prototype, {
        $shareyueApi: {
            get() {
                result.$vue = this;
                return result;
            }
        }
    });

    if (process.env.NODE_ENV === "development")
        console.error(result);
}

Vue.use(ShareyueApiPlugin, {})