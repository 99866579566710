export default function (Vue, pluginOptions, owner) {
    let result = {};

    function checkIdCard(idCard) {
        idCard = idCard.toUpperCase();
        //身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X。
        if (!(/(^\d{15}$)|(^\d{17}([0-9]|X)$)/.test(idCard))) {
            return false;
        }
        //校验位按照ISO 7064:1983.MOD 11-2的规定生成，X可以认为是数字10。
        //下面分别分析出生日期和校验位
        let len, re;
        len = idCard.length;
        if (len == 15) {
            re = new RegExp(/^(\d{6})(\d{2})(\d{2})(\d{2})(\d{3})$/);
            let arrSplit = idCard.match(re);

            //检查生日日期是否正确
            let dtmBirth = new Date('19' + arrSplit[2] + '/' + arrSplit[3] + '/' + arrSplit[4]);
            let bGoodDay;
            bGoodDay = (dtmBirth.getFullYear() == Number(arrSplit[2])) && ((dtmBirth.getMonth() + 1) == Number(arrSplit[
                    3])) &&
                (dtmBirth.getDate() == Number(arrSplit[4]));
            if (!bGoodDay) {
                return false;
            } else {
                //将15位身份证转成18位
                //校验位按照ISO 7064:1983.MOD 11-2的规定生成，X可以认为是数字10。
                let arrInt = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2);
                let arrCh = new Array('1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2');
                let nTemp = 0,
                    i;
                idCard = idCard.substr(0, 6) + '19' + idCard.substr(6, idCard.length - 6);
                for (i = 0; i < 17; i++) {
                    nTemp += idCard.substr(i, 1) * arrInt[i];
                }
                idCard += arrCh[nTemp % 11];
                return true;
            }
        }
        if (len == 18) {
            re = new RegExp(/^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)$/);
            let arrSplit = idCard.match(re);

            //检查生日日期是否正确
            let dtmBirth = new Date(arrSplit[2] + "/" + arrSplit[3] + "/" + arrSplit[4]);
            let bGoodDay;
            bGoodDay = (dtmBirth.getFullYear() == Number(arrSplit[2])) && ((dtmBirth.getMonth() + 1) == Number(arrSplit[
                    3])) &&
                (dtmBirth.getDate() == Number(arrSplit[4]));
            if (!bGoodDay) {
                return false;
            } else {
                //检验18位身份证的校验码是否正确。
                //校验位按照ISO 7064:1983.MOD 11-2的规定生成，X可以认为是数字10。
                let valnum;
                let arrInt = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2);
                let arrCh = new Array('1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2');
                let nTemp = 0,
                    i;
                for (i = 0; i < 17; i++) {
                    nTemp += idCard.substr(i, 1) * arrInt[i];
                }
                valnum = arrCh[nTemp % 11];
                if (valnum != idCard.substr(17, 1)) {
                    return false;
                }
                return true;
            }
        }
        return false;
    }

    function checkPhone(phone) {
        var reg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
        return reg.test(phone);
    }

    Object.assign(result, {
        checkIdCard,
        checkPhone
    });

    return result;
}