import merge from 'webpack-merge'
export default function (Vue, pluginOptions, owner) {
    let result = {};

    Object.assign(result, {
        isDev: process.env.NODE_ENV === "development",
        replaceRoute(options) {
            let opts = Object.assign({}, {}, options);
            this.$vue.$router.replace({
                query: merge(this.$vue.$route.query, opts)
            })
        }
    });

    return result;
}