export default function (Vue, pluginOptions, owner) {
    let result = {};

    Object.assign(result, {
        getBiochips(options) {
            let opts = Object.assign({}, {

            }, options);
            opts.page = opts.page - 1;
            return new Promise((resolve, reject) => {
                this.$vue.$shareyue.requestGet({
                    api: "admin/biochip",
                    data: opts
                }).then(res => {
                    resolve(res.data);
                }).catch(reject);
            });
        },
        deleteBiochip(id) {
            this.$vue.$store.commit('showLoading');
            return new Promise((resolve, reject) => {
                this.$vue.$shareyue.requestDelete({
                    api: `admin/biochip/${id}`
                }).then(res => {
                    switch (res.status) {
                        case 204: {
                            resolve(res);
                            break;
                        }
                        default: {
                            reject();
                        }
                    }
                }).catch(reject).finally(() => {
                    this.$vue.$store.commit('hideLoading');
                });
            });
        },
        exportBiochipExcel(options) {
            this.$vue.$store.commit('showLoading');
            let opts = Object.assign({}, {
                singleWorksheet: false
            }, options);
            let now = new Date();
            let fileName = `${now.getFullYear()}${now.getMonth()+1}${now.getDate()}${now.getHours()}${now.getMinutes()}.xlsx`;
            return new Promise((resolve, reject) => {
                this.$vue.$shareyue.requestDownload({
                    api: "admin/biochip/export",
                    data: opts,
                    fileName
                }).then(res => {
                    resolve();
                }).catch(reject).finally(() => {
                    this.$vue.$store.commit('hideLoading');
                });
            });
        },
        importBiochipExcel(options) {
            this.$vue.$store.commit('showLoading');
            let opts = Object.assign({}, {
                file: "",
                peasantHouseholdId: null
            }, options);
            let now = new Date();
            return new Promise((resolve, reject) => {
                this.$vue.$shareyue.requestPost({
                    api: "admin/biochip/import",
                    data: opts
                }).then(res => {
                    switch (res.status) {
                        case 200: {
                            resolve(res);
                            break;
                        }
                        default: {
                            reject(res);
                            break;
                        }
                    }
                }).catch(reject).finally(() => {
                    this.$vue.$store.commit('hideLoading');
                });
            });
        }
    });

    return result;
}