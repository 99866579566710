export default function (Vue, pluginOptions, owner) {
    let result = {};

    let _accessToken = localStorage.getItem("shareyue_accessToken");
    let _refreshToken = localStorage.getItem("shareyue_refreshToken")
    let isDev = process.env.NODE_ENV === "development";
    if (isDev) {
        console.error(_accessToken, _refreshToken);
    }

    function _saveToken() {
        localStorage.setItem("shareyue_accessToken", _accessToken);
        localStorage.setItem("shareyue_refreshToken", _refreshToken);
    }

    async function _request(method, options) {
        let opts = Object.assign({}, {
            api: "",
            data: "",
            fileName: ""
        }, options);

        opts.data = Object.assign({}, {
            _: new Date().getTime()
        }, opts.data);

        return new Promise((resolve, reject) => {
            let requestOptions = {
                url: `${pluginOptions.api}${opts.api}`,
                method: method,
                headers: {
                    Authorization: `${_accessToken}`
                }
            };
            var isDownload = false;
            switch (method) {
                case "get": {
                    requestOptions.params = opts.data;
                    break;
                }
                case "post": {
                    requestOptions.headers["Content-Type"] = 'application/json';
                    requestOptions.data = opts.data;
                    break;
                }
                case "put": {
                    requestOptions.headers["Content-Type"] = 'application/json';
                    requestOptions.data = opts.data;
                    break;
                }
                case "delete": {
                    requestOptions.params = opts.data;
                    break;
                }
                case "download": {
                    requestOptions.method = "get";
                    requestOptions.headers["Content-Type"] = 'application/json';
                    requestOptions.params = opts.data;
                    requestOptions.responseType = "blob";
                    isDownload = true;
                    break;
                }
                case "uploadfile": {
                    requestOptions.method = "post";
                    requestOptions.headers["Content-Type"] = 'multipart/form-data';
                    let data = new FormData();
                    opts.files.forEach(file => {
                        data.append("files", file);
                    });
                    requestOptions.data = data;
                    break;
                }
            }
            this.$vue.$axios(requestOptions).then(res => {
                if (isDownload) {
                    var blob = new Blob([res.data]);
                    var url = window.URL.createObjectURL(blob);
                    var aLink = document.createElement("a");
                    aLink.style.display = "none";
                    aLink.href = url;
                    aLink.setAttribute("download", opts.fileName);
                    document.body.appendChild(aLink);
                    aLink.click();
                    document.body.removeChild(aLink);
                    window.URL.revokeObjectURL(url);
                }
                resolve(res);
            }).catch(error => {
                let res = error.response
                switch (res.status) {
                    case 401: {
                        if (res.headers["token-expired"] == "true") {
                            this.$vue.$shareyueApi.refreshToken().then(res => {
                                _request.call(this, method, options).then(resolve).catch(reject);
                            }).catch(res => {
                                owner.goToLogin();
                            });
                        } else {
                            owner.goToLogin();
                        }
                        break;
                    }
                    default: {
                        resolve(res);
                        break;
                    }
                }
            });
        });
    }


    Object.assign(result, {
        async requestGet(options) {
            return _request.call(this, "get", options);
        },
        async requestPost(options) {
            return _request.call(this, "post", options);
        },
        async requestPut(options) {
            return _request.call(this, "put", options);
        },
        async requestDelete(options) {
            return _request.call(this, "delete", options);
        },
        async requestDownload(options) {
            return _request.call(this, "download", options);
        },
        async uploadFile(options) {
            let opts = Object.assign({}, {
                api: "file",
                files: []
            }, options);
            return _request.call(this, "uploadfile", opts);
        },
        setToken(options) {
            let opts = Object.assign({}, {
                accessToken: "",
                refreshToken: ""
            }, options);

            _accessToken = opts.accessToken;
            _refreshToken = opts.refreshToken;

            _saveToken();
        },
        getToken() {
            return {
                accessToken: _accessToken,
                refreshToken: _refreshToken
            }
        },
        deleteToken() {
            _accessToken = "";
            _refreshToken = "";
            _saveToken();
        }
    });

    Object.assign(result, {
        goToLogin() {
            this.$vue.$router.push(pluginOptions.login);
        }
    });
    return result;
}